@font-face {
  font-family: "NeuePlakText";
  src: url('../assets/fonts/NeuePlakTextLight.woff2') format('woff2'), url('../assets/fonts/NeuePlakTextLight.woff') format('woff');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "NeuePlakText";
  src: url('../assets/fonts/NeuePlakTextSemiBold.woff2') format('woff2'), url('../assets/fonts/NeuePlakTextSemiBold.woff') format('woff');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "NeuePlakText";
  src: url('../assets/fonts/NeuePlakTextSemiBold.woff2') format('woff2'), url('../assets/fonts/NeuePlakTextSemiBold.woff') format('woff');
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "NeuePlakText";
  src: url('../assets/fonts/NeuePlakTextBold.woff2') format('woff2'), url('../assets/fonts/NeuePlakTextBold.woff') format('woff');
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "NeuePlakText";
  src: url('../assets/fonts/NeuePlakTextBold.woff2') format('woff2'), url('../assets/fonts/NeuePlakTextBold.woff') format('woff');
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: "NeuePlakText";
  src: url('../assets/fonts/NeuePlakTextBold.woff2') format('woff2'), url('../assets/fonts/NeuePlakTextBold.woff') format('woff');
  font-weight: 900;
  font-display: swap;
}

@font-face {
  font-family: "NeuePlakCompressed";
  src: url('../assets/fonts/NeuePlakCompressedBold.woff2') format('woff2'), url('../assets/fonts/NeuePlakCompressedBold.woff') format('woff');
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: "NeuePlakCompressed";
  src: url('../assets/fonts/NeuePlakCompressedBold.woff2') format('woff2'), url('../assets/fonts/NeuePlakCompressedBold.woff') format('woff');
  font-display: swap;
  font-weight: 500;
}

@font-face {
  font-family: "NeuePlakCompressed";
  src: url('../assets/fonts/NeuePlakCompressedBold.woff2') format('woff2'), url('../assets/fonts/NeuePlakCompressedBold.woff') format('woff');
  font-display: swap;
  font-weight: 600;
}

@font-face {
  font-family: "NeuePlakCompressed";
  src: url('../assets/fonts/NeuePlakCompressedBold.woff2') format('woff2'), url('../assets/fonts/NeuePlakCompressedBold.woff') format('woff');
  font-display: swap;
  font-weight: 700;
}

