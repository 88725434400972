@import '@angular/cdk/overlay-prebuilt.css';

@import './styles/fonts.scss';
/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

// Define your breakpoints
$breakpoint-mobile: 768px;
$breakpoint-tablet: 1024px;
$breakpoint-desktop: 1025px;

button {
  font-weight: 700;
  position: relative;
  font-family: 'NeuePlakText';
  --padding-top: 4px;
  text-transform: capitalize;
}

// Global page container styles

.layout {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 1rem;

  // Default (mobile)
  max-width: 640px;

  // Small tablets and up (≥768px)
  @media (min-width: 768px) {
    max-width: 768px;
  }

  // Tablets and up (≥1024px)
  @media (min-width: 1024px) {
    max-width: 1024px;
  }

  // Desktop and up (≥1280px)
  @media (min-width: 1280px) {
    max-width: 1280px;
  }

  // Large Desktop and up (≥1536px)
  @media (min-width: 1536px) {
    max-width: 1536px;
  }
}

.page {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 1rem;

  // Default (mobile)
  max-width: 640px;

  // Small tablets and up (≥768px)
  @media (min-width: 768px) {
    max-width: 768px;
    padding: 1.5rem;
  }

  // Tablets and up (≥1024px)
  @media (min-width: 1024px) {
    max-width: 1024px;
    padding: 2rem;
  }

  // Desktop and up (≥1280px)
  @media (min-width: 1280px) {
    max-width: 1280px;
    padding: 2.5rem;
  }

  // Large Desktop and up (≥1536px)
  @media (min-width: 1536px) {
    max-width: 1536px;
    padding: 3rem;
  }
}

// Reusable mixins
@mixin mobile {
  @media (max-width: $breakpoint-mobile) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$breakpoint-mobile + 1}) and (max-width: $breakpoint-tablet) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}


:root {
  --font-sans: ''
}

:root {
  --background: 0 0% 100%;
  --foreground: 222.2 84% 4.9%;
  --muted: 210 40% 96.1%;
  --muted-foreground: 215.4 16.3% 46.9%;
  --popover: 0 0% 100%;
  --popover-foreground: 222.2 84% 4.9%;
  --card: 0 0% 100%;
  --card-foreground: 222.2 84% 4.9%;
  --border: 214.3 31.8% 91.4%;
  --input: 214.3 31.8% 91.4%;
  --primary: 222.2 47.4% 11.2%;
  --primary-foreground: 210 40% 98%;
  --secondary: 210 40% 96.1%;
  --secondary-foreground: 222.2 47.4% 11.2%;
  --accent: 210 40% 96.1%;
  --accent-foreground: 222.2 47.4% 11.2%;
  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 210 40% 98%;
  --ring: 222.2 84% 4.9%;
  --radius: 0rem;
  color-scheme: light;
}

.dark {
  --background: 222.2 84% 4.9%;
  --foreground: 210 40% 98%;
  --muted: 217.2 32.6% 17.5%;
  --muted-foreground: 215 20.2% 65.1%;
  --popover: 222.2 84% 4.9%;
  --popover-foreground: 210 40% 98%;
  --card: 222.2 84% 4.9%;
  --card-foreground: 210 40% 98%;
  --border: 217.2 32.6% 17.5%;
  --input: 217.2 32.6% 17.5%;
  --primary: 210 40% 98%;
  --primary-foreground: 222.2 47.4% 11.2%;
  --secondary: 217.2 32.6% 17.5%;
  --secondary-foreground: 210 40% 98%;
  --accent: 217.2 32.6% 17.5%;
  --accent-foreground: 210 40% 98%;
  --destructive: 0 62.8% 30.6%;
  --destructive-foreground: 210 40% 98%;
  --ring: 212.7 26.8% 83.9;
  color-scheme: dark;
}
